import React, { useCallback, useRef, useEffect } from 'react';
import gsap from 'gsap';
import { SplitText as gsapSplitText } from 'gsap/SplitText';
import PropTypes from 'prop-types';
import FontFaceObserver from 'fontfaceobserver';
import { SplitTextWrapper } from './index.style';
import WrapBalancer from 'react-wrap-balancer';

gsap.registerPlugin(gsapSplitText);

// ANIMATION TURNED OFF

const AnimateSplitText = ({ children, type, delay, forceHidden }) => {
  const content = useRef();
  const splitContent = useRef();
  const childrenArray = children.props ? children.props.children : children;
  const ariaLabel =
    typeof children === 'object'
      ? React.Children.toArray(childrenArray)
          .filter((item) => (typeof item === 'object' ? '' : item))
          .join('')
      : children;

  const build = useCallback(() => {
    splitContent.current = new gsapSplitText(content.current, {
      type: type,
      span: true,
    });

    const staggerAmount = 0.1;

    splitContent.current[type].forEach((item, itemIndex) => {
      item.classList.add('split__mask');
      item.setAttribute('aria-hidden', true);
      item.style.setProperty(
        '--splitTextDelay',
        `${itemIndex * staggerAmount}s`,
      );
      item.innerHTML = `<span class="split__text">${item.innerHTML}</span>`;
    });
  }, [type]);

  const update = useCallback(() => {
    splitContent.current.revert();
    build();
  }, [build]);

  const textRef = useCallback(
    (node) => {
      if (node !== null && !node.classList.contains('split')) {
        const fontA = new FontFaceObserver('Fellix'),
          fontB = new FontFaceObserver('FraktionMono');

        Promise.all([fontA.load(), fontB.load()]).then(
          () => {
            content.current = node;
            build();

            setTimeout(() => {
              content.current.classList.add('split');
            }, 200);

            window.addEventListener('resize', update);
          },
          () => {
            console.error('Font is not available');
          },
        );
      }
    },
    [build, update],
  );

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', update);
    };
  }, [update]);

  return <WrapBalancer>{children}</WrapBalancer>;

  return (
    <SplitTextWrapper
      ref={textRef}
      delay={delay}
      forceHidden={forceHidden}
      aria-label={ariaLabel}
    >
      {children}
    </SplitTextWrapper>
  );
};

AnimateSplitText.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  type: PropTypes.oneOf(['lines', 'words', 'chars', 'words,chars']),
  forceHidden: PropTypes.bool,
};

AnimateSplitText.defaultProps = {
  type: 'lines',
  delay: 0,
  forceHidden: false,
};

export default AnimateSplitText;

import React from 'react';
import PropTypes from 'prop-types';
// import { FadeInWrapper } from './index.style'

/*
 ** Mostly used on buttons at the minute
 ** No specific instructions from designers but looks like they just fade in on the animatic
 */

// ANIMATION TURNED OFF

const AnimateFadeIn = ({ delay, children }) => {
  return <>{children}</>;
  // return <FadeInWrapper delay={delay}>{children}</FadeInWrapper>
};

AnimateFadeIn.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
};

AnimateFadeIn.defaultProps = {
  delay: 0,
};

export default AnimateFadeIn;

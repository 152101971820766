import styled from 'styled-components';
import { easings } from '../../../styles/vars/easings.style';

const BaseSplitTextWrapper = styled.span`
  display: block;
  span {
    display: inline-block;
  }
  a {
    white-space: break-spaces;
  }
  .split__text {
    will-change: opacity, transform;
    opacity: 0;
    transform: translateY(3rem);
    transition: opacity 0.3s ${easings.text.in},
      transform 0.3s ${easings.text.in};
  }
  [data-inview='true'] &.split .split__mask .split__text {
    opacity: 1;
    transform: translate(0, 0);
  }
`;
export const SplitTextWrapper = styled(BaseSplitTextWrapper)`
  .split__mask .split__text {
    ${({ delay }) => {
      let itemDelay = 0.3;
      return `transition-delay: calc(${delay}s + ${itemDelay} * var(--splitTextDelay));`;
    }}

    [data-inview='false'] & {
      transition-delay: 0s;
      transition: opacity 0.3s ${easings.text.in}, transform 0s 0.3s;
    }
  }
`;
